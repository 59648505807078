<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-14 17:08:44
 * @LastEditTime: 2022-07-29 15:01:11
 * @LastEditors: cyy
 * @Description: 数据效果
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\dataeffect.vue
-->
<template>
  <div class="dataeffect">
    <template v-if="$route.name == 'dataeffect'">
      <div class="detail">
        <el-descriptions>
          <el-descriptions-item label="类型">
            {{ detailist.type == 1 ? '通用优惠券' : '专属优惠券' }}
          </el-descriptions-item>
          <el-descriptions-item label="优惠券名称">
            {{ detailist.name }}
          </el-descriptions-item>
          <el-descriptions-item label="优惠方式">
            {{
              detailist.pre_way == 1
                ? `满减${detailist.cou_price}元`
                : `打${detailist.discount}折`
            }}
          </el-descriptions-item>
          <el-descriptions-item label="使用门槛">
            {{
              detailist.threshold == 0
                ? '无门槛'
                : `满${detailist.threshold}元可用`
            }}
          </el-descriptions-item>
          <el-descriptions-item label="有效期">
            {{ detailist.validity }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="e_contain">
        <div class="title">效果数据</div>
        <!-- <div class="info">统计截至前一天24:00的数据</div> -->
        <div class="data_contain">
          <div class="item" v-for="item in datacontain" :key="item.id">
            <div class="name">
              {{ item.name }}
              <helpIcon
                style="margin-left: 6px"
                :content="item.prompt"
              ></helpIcon>
            </div>
            <div class="number">{{ item.num }}</div>
          </div>
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="定向发放记录" name="1"></el-tab-pane>
          <el-tab-pane label="用券商品" name="2"></el-tab-pane>
          <el-tab-pane label="领/用券用户" name="3"></el-tab-pane>
        </el-tabs>

        <!-- 定向发放 -->
        <recordList v-if="activeName == 1"></recordList>

        <!-- 用券商品 -->
        <usecoupon v-if="activeName == 2"></usecoupon>

        <!-- 领/用券用户 -->
        <coupons-user v-if="activeName == 3"></coupons-user>
      </div>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import recordList from './components/recordList'
import usecoupon from './components/usecoupon'
import couponsUser from './components/couponsUser'

export default {
  name: 'dataeffect',

  components: {
    recordList,
    usecoupon,
    couponsUser,
  },

  data() {
    return {
      activeName: '1',

      // 列表
      datacontain: [],

      // 数据参数
      effectlist: {},

      // 优惠券详情
      detailist: {},
    }
  },

  computed: {
    coupon_id() {
      return this.$route.params.id
    },
  },

  created() {
    this.setCoupon()
    this.effectData()
  },

  methods: {
    async effectData() {
      const { data } = await this.$http({
        url: '/coupon/effectData',
        data: {
          coupon_id: this.coupon_id,
        },
      })
      this.effectlist = data
      this.datacontain = [
        {
          id: 1,
          name: '用券总成交额',
          prompt: '使用该优惠券的订单付款总金额',
          num: `￥${this.effectlist.all_price}`,
        },
        {
          id: 2,
          name: '优惠总金额',
          prompt: '使用该优惠券所优惠的总金额',
          num: `￥${this.effectlist.all_discount_amount}`,
        },
        {
          id: 3,
          name: '费效比',
          prompt: '优惠总金额/用券总成交额',
          num: `${this.effectlist.ratio}%`,
        },
        {
          id: 4,
          name: '客单价',
          prompt: '用券总成交额/使用该优惠券的付款订单数',
          num: `￥${this.effectlist.unit_price}`,
        },
        {
          id: 5,
          name: '领券总用户数',
          prompt: '优惠券到账的用户数',
          num: this.effectlist.receive_user_num,
        },
        {
          id: 6,
          name: '用券总用户数',
          prompt: '使用该优惠券的成交用户数',
          num: this.effectlist.use_user_num,
        },
      ]
    },

    async setCoupon() {
      const { data } = await this.$http({
        url: `/coupon/couponHead`,
        data: { coupon_id: this.coupon_id },
      })
      this.detailist = data
    },
  },
}
</script>

<style lang="scss" scoped>
.dataeffect {
  .detail {
    background-color: #fff;
    padding: 20px 20px 10px;
    margin-bottom: 20px;
  }
  .e_contain {
    background-color: #fff;
    padding: 20px;
    .info {
      font-size: 14px;
      color: #666666;
      margin-top: 12px;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      height: 1px;
    }
    .data_contain {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      margin-bottom: 30px;

      .item {
        width: 130px;
        height: 100px;
        background: #f5f7fa;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .name {
          font-size: 14px;
          color: #666666;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
        }
        .number {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
        }
      }
    }
  }
}
</style>
