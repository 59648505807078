<!--
 * @Author: cyy
 * @version: 1.1.14
 * @Date: 2022-07-14 18:19:40
 * @LastEditTime: 2022-07-29 15:04:30
 * @LastEditors: cyy
 * @Description: 用券商品
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\components\usecoupon.vue
-->
<template>
  <div class="usecoupon">
    <div class="search">
      <el-select
        v-model="good_type"
        style="width:140px"
        size="medium"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <pagination2
      :option="{ coupon_id, good_type }"
      url="/coupon/buyGoodsList"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="商品名称">
            <template slot-scope="{ row }">
              <div class="name_tu">
                <div class="tu">
                  <img :src="row.cover_img" alt="" />
                </div>
                <div class="name">
                  {{ row.name }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="商品价格">
            <template slot-scope="{ row }">
              <div class="redcolor">
                <template v-if="row.good_type == 7">
                  <p v-for="(item, index) in row.price" :key="index">
                    ￥{{ item }}
                  </p>
                </template>
                <template v-else>￥{{ row.price }}</template>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="商品类型">
            <template slot-scope="{ row }">
              {{ row.good_type | getType }}
            </template>
          </el-table-column>
          <el-table-column label="购买人数">
            <template slot-scope="{ row }">
              {{ row.user_num }}
            </template>
          </el-table-column>
          <el-table-column label="购买次数">
            <template slot-scope="{ row }">{{ row.count }}</template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  name: 'usecoupon',

  data() {
    return {
      good_type: 0,

      options: [
        {
          value: 0,
          label: '全部商品类型',
        },
        {
          value: 1,
          label: '直播课',
        },
        {
          value: 3,
          label: '录播课',
        },
        {
          value: 2,
          label: '小班课',
        },
        {
          value: 4,
          label: '系列课',
        },
        {
          value: 5,
          label: '公开课',
        },
        {
          value: 6,
          label: '电子资料',
        },
        {
          value: 7,
          label: '会员',
        },
        {
          value: 8,
          label: '知识商品',
        },
      ],
    }
  },

  computed: {
    coupon_id() {
      return this.$route.params.id
    },
  },

  filters: {
    getType(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break

        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 5:
          text = '公开课'
          break
        case 6:
          text = '电子资料'
          break
        case 7:
          text = '会员'
          break
        case 8:
          text = '知识商品'
          break
      }
      return text
    },
  },

  methods: {
    toDetail(row) {
      this.$router.push({
        path: `/coupon/dataeffect/${this.coupon_id}/directional/${row.grant_id}`,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.usecoupon {
  .search {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .name_tu {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
    .tu {
      width: 66px;
      height: 38px;
      background: #d8d8d8;
      margin-right: 14px;
    }
    .name {
      font-size: 16px;
      color: #333333;
    }
  }
}
</style>
