<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-14 18:19:40
 * @LastEditTime: 2022-07-29 18:59:12
 * @LastEditors: cyy
 * @Description: 领/用券用户
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\components\couponsUser.vue
-->
<template>
  <div class="couponsUser">
    <div class="search">
      <el-button type="primary" size="medium" @click="exportTable">
        导出
      </el-button>
      <div class="flex-center">
        <el-select
          style="width:140px"
          v-model="post.collection_type"
          size="medium"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-select
          style="width:140px"
          v-model="post.status"
          class="ml20 mr20"
          size="medium"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-input
          size="medium"
          style="width:218px"
          v-model="post.search"
          clearable
          placeholder="输入名称搜索"
          @keyup.enter.native="search"
        ></el-input>

        <el-button type="primary" class="ml20" size="medium" @click="search">
          搜索
        </el-button>
      </div>
    </div>
    <pagination2
      :option="post2"
      url="/coupon/collectionList"
      ref="childDialogBox"
      @complete="complete"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="用户名称">
            <template slot-scope="{ row }">
              <div class="user">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name">
                  {{ row.uname }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="手机号" prop="umobile">
            <template slot-scope="{ row }">
              {{ row.umobile ? row.umobile : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="领券方式">
            <template slot-scope="{ row }">
              {{ row.collection_type == 1 ? '用户领取' : '定向发放' }}
            </template>
          </el-table-column>
          <el-table-column label="领券时间">
            <template slot-scope="{ row }">
              <span v-if="row.collection_time > 0">
                {{ row.collection_time | formatTimeStamp }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="{ row }">
              {{ row.status | getType }}
            </template>
          </el-table-column>
          <el-table-column label="用券时间">
            <template slot-scope="{ row }">
              <span v-if="row.usage_time > 0">
                {{ row.usage_time | formatTimeStamp }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <span
                v-if="row.status == 3 || row.status == 2"
                style="color:#0AA29B"
              >
                --
              </span>
              <el-button v-else type="text" @click="toVoid(row)">
                作废
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  name: 'couponsUser',

  data() {
    return {
      post2: {
        coupon_id: '',
      },

      post: {
        coupon_id: '',
        collection_type: 0,
        status: 0,
        search: '',
      },

      options: [
        {
          value: 0,
          label: '全部领取方式',
        },
        {
          value: 1,
          label: '用户领取',
        },
        {
          value: 2,
          label: '定向发放',
        },
      ],

      options2: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '已领取',
        },
        {
          value: 2,
          label: '已使用',
        },
        {
          value: 3,
          label: '已作废',
        },
      ],
    }
  },

  mounted() {
    this.post2.coupon_id = this.coupon_id
  },

  computed: {
    coupon_id() {
      return this.$route.params.id
    },
  },

  filters: {
    getType(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '已领取'
          break

        case 2:
          text = '已使用'
          break
        case 3:
          text = '已作废 '
          break
      }
      return text
    },
  },

  methods: {
    complete(val) {
      const { count } = val
      this.count = count
    },

    // 导出
    exportTable() {
      if (this.count > 0) {
        this.$confirm(`确定要导出当前${this.count}条数据？`, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          // 所有搜索条件get传递过去
          let query = JSON.stringify(_.assign({}, this.post2))
          this.$store.dispatch(
            'open',
            `${location.origin}/coupon/exportExcel?query=${encodeURIComponent(
              query
            )}`
          )
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },

    search() {
      this.post2 = _.assign({}, this.post, { coupon_id: this.coupon_id })
      this.$refs.childDialogBox.reset()
    },

    async toVoid(row) {
      this.$confirm(
        '确认作废吗？作废优惠券后，该用户将无法使用此优惠券，请提前告知用户。',
        '作废优惠券',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(async () => {
          await this.$http({
            url: '/coupon/cancel',
            data: { cuc_id: row.cuc_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.couponsUser {
  .search {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  .user {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
    .tu {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 10px;
    }
    .name {
      font-size: 14px;
      color: #333333;
    }
  }
}
</style>
