<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-14 18:19:40
 * @LastEditTime: 2022-07-19 16:08:10
 * @LastEditors: cyy
 * @Description: 定向发放
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\coupon\components\recordList.vue
-->
<template>
  <div class="recordList">
    <pagination2
      :option="{ coupon_id }"
      url="/coupon/recordList"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="发放时间">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="发放数量(张)">
            <template slot-scope="{ row }">
              {{ row.count }}
            </template>
          </el-table-column>
          <el-table-column label="发放对象">
            <template slot-scope="{ row }">
              {{ row.grant_type | getType }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button type="text" @click="toDetail(row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  name: 'recordList',

  data() {
    return {}
  },

  computed: {
    coupon_id() {
      return this.$route.params.id
    },
  },

  filters: {
    getType(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '付费用户-指定付费时间'
          break

        case 2:
          text = '付费用户-指定付费商品'
          break
        case 3:
          text = '用户群体-指定学员分组'
          break
        case 4:
          text = '用户群体-指定学员标签'
          break
        case 5:
          text = '指定用户'
          break
      }
      return text
    },
  },

  methods: {
    toDetail(row) {
      this.$router.push({
        path: `/coupon/dataeffect/${this.coupon_id}/directional/${row.grant_id}/${row.grant_type}`,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.recordList {
}
</style>
